import { forwardRef, useImperativeHandle } from "react";
import { QuotationFields, QuotationResults } from "@components";
import { Grid } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { ComparisonViewMode } from "@helpers/enum";
import { MAX_NUMBER_OF_QUOTATIONS } from "@helpers/const";
import {
  useQuotationFormContext,
  useCalculationsContext,
  useGetCalculateQuotePayload,
  QuotationForm,
  useCalculationsActionsContext,
  useGetMutateQuotationPayload,
  getDefaultQuotationFormValues,
  useFinanceTypeConfiguration,
  useValidateCalculatePayload,
  useGetChartEvaluationPayload,
  useExternalDataContext,
  useGetTaxPayload,
} from "@ntpkunity/controls-common";
import { QuotationHandle } from "@_types/quotation";
import { useGetAllPermissions } from "@ntpkunity/controls-ums";

type SingleQuotationProps = {
  headerText: string;
  errorMessage?: string;
  totalQuotations: number;
  viewMode: ComparisonViewMode;
  isSelected: boolean;
  handleDeleteQuotation: () => void;
  handleSelectQuotation: () => void;
  onErrorClose: () => void;
};

export const SingleQuotation = forwardRef(
  (
    {
      viewMode,
      totalQuotations,
      headerText,
      errorMessage,
      isSelected,
      handleDeleteQuotation,
      handleSelectQuotation,
      onErrorClose,
    }: SingleQuotationProps,
    ref: React.ForwardedRef<QuotationHandle>
  ) => {
    const theme = useTheme();
    const quotationFormContext = useQuotationFormContext<QuotationForm>();
    const calculationsContext = useCalculationsContext();
    const getCalculateQuotePayload = useGetCalculateQuotePayload();
    const getTaxPayload = useGetTaxPayload();
    const calculationActions = useCalculationsActionsContext();
    const getSaveQuotationPayload = useGetMutateQuotationPayload({
      forUpdate: false,
    });
    const validatePayload = useValidateCalculatePayload();
    const { permissions } = useGetAllPermissions();
    const getChartEvaluationPayload = useGetChartEvaluationPayload();
    const financeType = quotationFormContext.watch("financeType");
    const externalDataValues = useExternalDataContext();

    const columnSpan = totalQuotations === MAX_NUMBER_OF_QUOTATIONS ? 4 : 6;
    const isInputMode = viewMode === ComparisonViewMode.INPUT;
    const isResultMode = viewMode === ComparisonViewMode.RESULT;

    useImperativeHandle(
      ref,
      () => {
        return {
          getQuotationFormContext: () => quotationFormContext,
          getCalculationsContext: () => calculationsContext,
          getCalculationActions: () => calculationActions,
          getTaxPayload,
          getCalculateQuotePayload,
          getSaveQuotationPayload,
          validatePayload,
          getChartEvaluationPayload,
        };
      },
      [
        quotationFormContext,
        calculationsContext,
        calculationActions,
        externalDataValues,
      ]
    );

    const configuration = useFinanceTypeConfiguration();
    const handleResetQuotation = () => {
      quotationFormContext.reset(
        getDefaultQuotationFormValues(configuration, permissions, financeType)
      );
      calculationActions.resetCalculationResults();
    };

    return (
      <>
        <Grid theme={theme} item xs={12} md={columnSpan} xl={columnSpan}>
          {isInputMode && (
            <QuotationFields
              headerText={headerText}
              totalQuotations={totalQuotations}
              onDelete={handleDeleteQuotation}
              onReset={handleResetQuotation}
              errorMessage={errorMessage}
              onErrorClose={onErrorClose}
            />
          )}
          {isResultMode && (
            <QuotationResults
              headerText={headerText}
              isSelected={isSelected}
              handleSelectQuotation={handleSelectQuotation}
            />
          )}
        </Grid>
      </>
    );
  }
);
