import {
  LOCAL_STORAGE_KEYS,
  PROPOSAL_AGE_CATEGORY,
  PROPOSAL_OPTION_TEXT_MAP,
  PROPOSAL_STATUS_TO_ROW_OPTION_MAP,
  PROPOSAL_STATUSES_BY_CATEGORY,
} from "./const";
import {
  BrokerSpecifcFormFields,
  ChartBaseData,
  DashboardAnalyticsResponse,
  TCustomer,
  TCustomerDetails,
  TOrderStakeholders,
  TProposalWorkQueueEntry,
  DownloadParams,
  GetDownloadPayloadParams,
  TDirector,
} from "@_types";
import {
  ClientTypes,
  FinanceType,
  OrderStatus,
  OrderStatusCategory,
  ProposalAgeCategories,
  ProposalStakeholders,
  SessionStorageKeys,
  TableRowOptions,
  Roles,
  DownloadDocument,
  DirectorRoles,
  CommentCategory,
} from "./enum";
import {
  getPaymentMode,
  TFinanceType,
  FeeName,
  AmountType,
} from "@ntpkunity/controls-common";
import { Theme } from "@mui/material";
import { CommonSetupProperties, FinanceTypeSetupRes } from "@_types/setups";
import { DealerProfile } from "@_types/company";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

export const getUserSettings = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS) || "{}");

export const getUserNameInitials = (userName: string) => {
  const namePart = userName?.trim().split(/\s+/) ?? [];
  return namePart?.map((part) => part.charAt(0).toUpperCase()).join("");
};

export const getUserDataFromToken = () => {
  try {
    const token = getUserSettings()?.access_token;
    return jwtDecode(token) as any;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return null;
  }
};
export const getSelectValues = <T extends string>(
  arr: ReadonlyArray<T>,
  disabledInfo?: Partial<{ [key in T]: boolean }>
): { text: T; value: string; disabled?: boolean }[] => {
  return arr.map((val: T) => {
    const returnObj = { text: val, value: val };
    if (disabledInfo && disabledInfo[val]) {
      return { ...returnObj, disabled: true };
    }
    return returnObj;
  });
};

export const pounds = Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const getQueryParamsString = <T extends {} = { [key: string]: string }>(
  params: T
) => {
  const queryString = Object.keys(params)
    .filter((key) => !!params[key])
    .map((key) => {
      if (Array.isArray(params[key])) {
        if (params[key].length) {
          const arrayParams = params[key].map((ele) => {
            return `${key}=${encodeURIComponent(ele)}`;
          });

          return arrayParams.join("&");
        } else {
          return null;
        }
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .filter(Boolean)
    .join("&");
  return queryString ? `?${queryString}` : "";
};

export const utcToLocalDateTime = (
  dateString: string
): { dateStr: string; timeStr: string } => {
  let dateISO: string;
  if (dateString) {
    dateISO =
      dateString.replace(" ", "T") + (dateString.includes("Z") ? "" : "Z");
  } else {
    dateISO = new Date().toISOString();
  }
  const date = new Date(dateISO);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatterDate = new Intl.DateTimeFormat("en-US", dateOptions);
  const formatterTime = new Intl.DateTimeFormat("en-US", timeOptions);
  const dateStr = formatterDate.format(date);
  let timeStr = formatterTime.format(date);
  timeStr = timeStr.replace(/AM|PM/, (match) => match.toLowerCase());
  return { dateStr, timeStr };
};

export const convertIsoDateStrToddmmyyyy = (date: string) => {
  if (!date) return date;
  return date.slice(0, 10).split("-").reverse().join("/");
};

export const convertIsoDateStrToddmmyyyyhms = (date: string) => {
  if (!date) return date;
  const dateObj = new Date(date);
  return `${dateObj
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join(
      "/"
    )} ${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
};
export const convertDateToISOString = (date: Date): string => {
  if (date.getFullYear() !== 1969 && date.getFullYear() !== 1970) {
    return date.toISOString();
  }
};

export const deleteNDays = (days: number) => {
  const date = new Date(Date.now());
  date.setDate(date.getDate() - days);
  return date;
};

export const convertFromSecondsToDays = (seconds: number) => {
  return Math.round(seconds / 86400);
};
export const getCustomerName = (customer: TCustomer) => {
  if (!customer || !customer.customer_type) return "";
  return customer.customer_type === ClientTypes.INDIVIDUAL
    ? `${customer.first_name || ""} ${customer.last_name || ""}`
    : customer.company_name;
};

export const getOrderStakeholders = (
  orderStakeholders: TOrderStakeholders
): { lenderName: string; dealerName: string } => {
  return {
    lenderName: orderStakeholders?.find(
      (stakeholder) => stakeholder.role === ProposalStakeholders.LENDER
    )?.name,
    dealerName: orderStakeholders?.find(
      (stakeholder) => stakeholder.role === ProposalStakeholders.DEALER
    )?.name,
  };
};

export const alphaNumericMask = (value: string) =>
  Array.from(value).map((_) => /[a-zA-Z0-9 ]/);

export const errorInQuotationDetails = (
  formValues: BrokerSpecifcFormFields
): { isError: true; message: string } | { isError: false } => {
  const errorValues: string[] = [];
  if (!formValues.name?.trim()) {
    errorValues.push("Quotation name");
  }

  if (errorValues.length) {
    let errorMsg = "";
    errorValues.forEach((value, index) => {
      errorMsg = errorMsg + (index === 0 ? value : ` and ${value} `);
    });
    errorMsg += " cannot be empty";
    return { isError: true, message: errorMsg };
  }
  return { isError: false };
};

export const dateHelper = (pDate: string | number | Date) => {
  let date = new Date(pDate);
  let dateDay = null;
  if (date.getDate() < 10) {
    dateDay = `0${date.getDate()}`;
  } else {
    dateDay = date.getDate();
  }
  return `${dateDay}-${date.toLocaleString("default", {
    month: "short",
  })}-${date.getFullYear()}`;
};

export const getAssetForQuotation = (assetStr: string) => {
  if (!assetStr) return undefined;
  const assetSplit = assetStr.split(" - ");
  const assetCategory = assetSplit[0];
  const assetType = assetSplit[1];
  const assetSubType = assetSplit[2];

  return {
    category: assetCategory,
    type: assetType,
    subType: assetSubType,
  };
};

export const getBrokerQuotationPayload = (
  quotationPayload: any,
  brokerFields: BrokerSpecifcFormFields,
  dealerAssociation: DealerProfile | undefined,
  forUpdate = false
) => {
  const userSettings = getUserSettings();
  const brokerId =
    userSettings?.role?.name == Roles.BROKER_USER
      ? getUserDataFromToken()?.user_id
      : null;

  const additionalQuoteDetails = {
    name: brokerFields.name,
    introducer_name: brokerFields.brokerName,
    dealer_id: dealerAssociation?.id,
    broker_id: brokerId,
    assets: [
      {
        identifier: forUpdate ? brokerFields.asset.identifier : undefined,
        asset_type: brokerFields.asset.type,
      },
    ],
  };
  if (forUpdate) {
    return {
      ...quotationPayload,
      ...additionalQuoteDetails,
      customer: {
        ...quotationPayload.customer,
        email: brokerFields.email,
        customer_type: brokerFields.clientType,
        identifier: brokerFields.identifier,
      },
      updated_by: getUserSettings()?.user_name || "",
    };
  } else {
    const [localPart, domain] =
      quotationPayload.quote_identifiers.email.split("@");
    const uuid = crypto.randomUUID();
    const newEmail = `${uuid}@${domain}`;
    return {
      ...quotationPayload,
      quote_details: {
        ...quotationPayload.quote_details,
        ...additionalQuoteDetails,
        created_by: getUserSettings()?.user_name || "",
      },
      quote_identifiers: {
        ...quotationPayload.quote_identifiers,
        email: newEmail,
        customer_type: brokerFields.clientType,
      },
    };
  }
};

export const getStatusColorMap = (theme: Theme) => {
  return {
    [OrderStatus.ACCEPTED]: theme.palette.success.main,
    [OrderStatus.WITHDRAWN]: theme.palette.error.main,
    [OrderStatus.DECLINED]: theme.palette.error.main,
    [OrderStatus.CONDITIONED]: theme.palette.warning.main,
    [OrderStatus.DOCUMENT_RECEIVED]: "#40CBE0",
    [OrderStatus.SENT_FOR_PAYOUT]: "#5E5CE6",
    [OrderStatus.PAID_OUT]: "#BF5AF2",
    [OrderStatus.DRAFT]: theme.palette.info.main,
    [OrderStatus.SUBMITTED]: theme.palette.info.main,
    [OrderStatus.DOCUMENT_SENT]: "#FFD60A",
    [OrderStatus.AWAITING_COMPLIANCE]: "#AC8E68",
    [OrderStatus.COMPLIED]: "#63E6E2",
    [OrderStatus.NEW]: theme.palette.info.main,
    [OrderStatus.ADDITIONAL_INFO_REQUIRED]: "#4D4D4D",
    [OrderStatus.CONDITIONAL_APPROVAL]: "#18807D",
  };
};

export const getCategoryColorMap = (theme: Theme) => {
  return {
    [OrderStatusCategory.ACTIVE]: theme.palette.info.main,
    [OrderStatusCategory.CLOSED]: theme.palette.error.main,
  };
};

export const getActiveProposalBaseData = (theme: Theme): ChartBaseData[] => {
  const colorMap = getStatusColorMap(theme);
  return PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE].map(
    (status) => {
      return {
        status: status,
        color: colorMap[status],
        count: 0,
      };
    }
  );
};

export const getProposalCategoryBaseData = (theme: Theme): ChartBaseData[] => {
  const colorMap = getCategoryColorMap(theme);
  return [OrderStatusCategory.ACTIVE, OrderStatusCategory.CLOSED].map(
    (status) => {
      return {
        status: status,
        color: colorMap[status],
        count: 0,
      };
    }
  );
};

export const getStatusCount = (
  proposals: DashboardAnalyticsResponse[],
  statuses: OrderStatus[]
) => {
  const statusToCount = statuses.reduce((prev, status) => {
    prev[status] = 0;
    return prev;
  }, {});

  proposals.forEach((dataItem) => {
    if (statusToCount[dataItem.status] !== undefined) {
      statusToCount[dataItem.status] = statusToCount[dataItem.status] + 1;
    }
  });

  return statusToCount;
};

export const reduceDataForProposalWidget = (
  theme: Theme,
  proposals: DashboardAnalyticsResponse[] | undefined
) => {
  if (!proposals) {
    return [];
  }
  const baseData = getActiveProposalBaseData(theme);
  const statusToCount = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE]
  );
  return baseData.map((baseData) => {
    return {
      ...baseData,
      count: statusToCount[baseData.status],
    };
  });
};

export const reduceDataForTotalProposalWidget = (
  theme: Theme,
  proposals: DashboardAnalyticsResponse[]
) => {
  if (!proposals) {
    return [];
  }

  const activeStatusToCounts = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE]
  );

  const totalActiveCounts = Object.keys(activeStatusToCounts).reduce(
    (prev, cur) => prev + activeStatusToCounts[cur],
    0
  );
  const closedStatusToCount = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.CLOSED]
  );
  const totalClosedCounts = Object.keys(closedStatusToCount).reduce(
    (prev, cur) => prev + closedStatusToCount[cur],
    0
  );

  const baseData = getProposalCategoryBaseData(theme);

  const categoryToCount = {
    [OrderStatusCategory.ACTIVE]: totalActiveCounts,
    [OrderStatusCategory.CLOSED]: totalClosedCounts,
  };

  return baseData.map((baseData) => {
    return {
      ...baseData,
      count: categoryToCount[baseData.status],
    };
  });
};

export const reduceDataForAgingProposalWidget = (
  proposals: DashboardAnalyticsResponse[]
) => {
  if (!proposals) {
    return [];
  }
  if (proposals?.length > 0) {
    const data = proposals.reduce(
      (acc, proposal) => {
        const proposalAge = convertFromSecondsToDays(
          Number(proposal.age_in_seconds ?? 0)
        );

        const range = Object.keys(PROPOSAL_AGE_CATEGORY).find((range) => {
          const [min, max] =
            PROPOSAL_AGE_CATEGORY[range as ProposalAgeCategories];
          return proposalAge >= min && proposalAge <= max;
        }) as ProposalAgeCategories;

        if (range) {
          acc[range].push(proposal);
        }
        return acc;
      },
      {
        [ProposalAgeCategories.LESS_THAN_TWO]: [],
        [ProposalAgeCategories.THREE_TO_FIVE]: [],
        [ProposalAgeCategories.SIX_TO_TEN]: [],
        [ProposalAgeCategories.MORE_THAN_TEN]: [],
      } as { [key in ProposalAgeCategories]: any }
    );

    return Object.entries(data).map((obj) => {
      return { category: obj[0], count: obj[1].length ?? 0 };
    });
  }
};

export const getProposalRowOptions = (
  proposal: TProposalWorkQueueEntry,
  disableDownload: boolean
) => {
  const { status } = proposal;
  const availableOptions = PROPOSAL_STATUS_TO_ROW_OPTION_MAP[status] || [];

  return availableOptions.map((optionKey) => ({
    optionText: PROPOSAL_OPTION_TEXT_MAP[optionKey],
    optionkey: optionKey,
    optionValue: proposal,
    disabled: optionKey === TableRowOptions.DOWNLOAD ? disableDownload : false,
  }));
};

export const getFormattedAddress = (address: TCustomerDetails["address"]) => {
  const items = [
    address.addressLine1,
    address.addressLine2,
    address.city,
    address.county,
    address.zipCode,
  ].filter(Boolean);
  return items.reduce((prev, cur, index) => {
    if (index) return `${prev}, ${cur}`;
    return cur;
  }, "");
};

export const getNumberOfYearsPassed = (date: Date) => {
  const currentDate = new Date();
  let noOfYears = currentDate.getFullYear() - date.getFullYear();
  if (
    currentDate.getMonth() < date.getMonth() ||
    (currentDate.getMonth() === date.getMonth() &&
      currentDate.getDate() < date.getDate())
  ) {
    noOfYears--;
  }
  return noOfYears;
};

export const setSessionStorageItem = <TData>(
  key: SessionStorageKeys,
  data: TData
) => {
  const dataString = JSON.stringify(data);
  sessionStorage.setItem(key, dataString);
};

export const getSessionStorageItem = <TData>(
  key: SessionStorageKeys
): TData | undefined => {
  const dataString = sessionStorage.getItem(key);
  if (!dataString) {
    return undefined;
  }
  return JSON.parse(dataString);
};

export const removeSessionStorageItem = (key: SessionStorageKeys) =>
  sessionStorage.removeItem(key);

export const removeDeletedEntities = <T extends CommonSetupProperties>(
  dontRemoveValue: string,
  options: T[]
) => {
  return options?.filter(
    (option) =>
      (dontRemoveValue && option.code === dontRemoveValue) || !option.is_deleted
  );
};

export const isDealerRole = (): boolean => {
  const userRoleName = getUserSettings()?.role?.name;
  return [Roles.BROKER_DEALER_ADMIN, Roles.BROKER_DEALER_USER].includes(
    userRoleName
  );
};

export const getEntityTypeDealer = () => {
  const userRoleName = getUserSettings()?.role?.name;
  return [Roles.BROKER_DEALER_ADMIN, Roles.BROKER_DEALER_USER].includes(
    userRoleName
  )
    ? ProposalStakeholders.DEALER
    : null;
};

export const getDownloadDocumentPayload = (
  params: GetDownloadPayloadParams
): DownloadParams => {
  const { financialCalculations, financialValues, setups, documentType } =
    params;
  const getClientTypeName = (code: string) =>
    setups.clientTypes?.find((ct) => ct.code === code)?.description;
  const assetCost = pounds.format(Number(financialValues.assetCost));
  const accountSettings = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS)
  );

  const fees = {
    annual_admin_fee: "",
    document_fee: "",
    otp_fee: "",
  };
  financialValues.fees?.forEach((fee) => {
    switch (fee.name) {
      case FeeName.ANNUAL_ADMIN_FEE:
        fees.annual_admin_fee = pounds.format(Number(fee.amount));
        break;
      case FeeName.DOC_FEE:
        fees.document_fee = pounds.format(Number(fee.amount));
        break;
      case FeeName.OTP_FEE:
        fees.otp_fee = pounds.format(Number(fee.amount));
    }
  });

  const commissionPercentage =
    financialValues.commissionType === AmountType.PERCENTAGE
      ? Number(financialValues.commission) || 0
      : ((Number(financialValues.commission) || 0) /
          (Number(financialCalculations.financeAmount) || 0)) *
        100;

  const commonPayload = {
    ...fees,
    processing_date: dateHelper(financialValues.startDate),
    asset_cost: assetCost,
    deposit_amount: pounds.format(Number(financialCalculations.depositAmount)),
    commission_amount: pounds.format(
      Number(financialCalculations.commissionAmount)
    ),
    commission_percentage: `${(commissionPercentage || 0).toFixed(2)}%`,
    rate: `${Number(financialValues.rate).toFixed(2)}%`,
    rate_type: financialValues.rateType,
    no_of_advance_payment: financialValues.noOfAdvancePayments,
    no_of_regular_payments: financialValues.noOfRegularPayments,
    total_rentals_without_balloon:
      Number(financialValues.noOfAdvancePayments || 0) +
      Number(financialValues.noOfRegularPayments || 0),
    balloon_payment: pounds.format(Number(financialValues.balloonPayment)),
    ballon_collection: financialValues.balloonCollection,
    vat_treatment: financialValues.vatType,
    non_vatable_amount: financialValues.nonVatableAmount,
    vat_amount: pounds.format(Number(financialValues.vatAmount)),
    vat_number: financialValues.vatNumber,
    vat_deferred_type: financialValues.vatDeferredType,
    payment_frequency: financialValues.paymentFrequency,
    payment_mode: getPaymentMode(financialValues.noOfAdvancePayments),
    finance_type: setups.financeTypes?.find(
      (ft) => ft.code === financialValues.financeType
    )?.name,
    finance_amount: pounds.format(Number(financialCalculations.financeAmount)),
    sum_of_advance_rentals: pounds.format(
      Number(financialCalculations.sumOfAdvanceRentals)
    ),
    sum_of_fees: pounds.format(Number(financialCalculations.sumOfFees)),
    sum_of_periodic_interest: pounds.format(
      Number(financialCalculations.sumOfPeriodicInterest)
    ),
    sum_of_rentals: pounds.format(Number(financialCalculations.sumOfRentals)),
    total_payables: pounds.format(Number(financialCalculations.totalPayables)),
    gross_yield: `${financialCalculations.rates.grossYield.toFixed(2)}%`,
    net_yield: `${financialCalculations.rates.netYield.toFixed(2)}%`,
    apr: `${financialCalculations.rates.apr.toFixed(2)}%`,
    flat_rate_excl_commission: `${financialCalculations.rates.flatRateExclCommission.toFixed(
      2
    )}%`,
    flat_rate_incl_commission: `${financialCalculations.rates.flatRateInclCommission.toFixed(
      2
    )}%`,
    term:
      Number(financialValues.noOfRegularPayments) +
      (Number(financialValues.balloonPayment) > 0
        ? Number(financialValues.balloonCollection)
        : 0),
    payments: financialCalculations.rentalSummary.map((rs) => ({
      payment_from: rs.startTerm,
      payment_to: rs.endTerm,
      amount: pounds.format(Number(rs.rentalAmount)),
      payment_type: rs.rentalType,
    })),
    fees_info: financialValues.fees.map((fee) => ({
      amount: pounds.format(Number(fee.amount)),
      frequency: fee.frequency,
      name: fee.name,
      occurence: fee.type,
    })),
    rental: pounds.format(
      Number(financialCalculations?.rentalSummary[0]?.rentalAmount)
    ),
    company_id: params.companyId,
    introducer_name: accountSettings?.user_name,
    introducer_email: accountSettings?.email,
  };

  if (documentType === DownloadDocument.QUOTATION) {
    const { additionalParams } = params;
    const asset = getAssetForQuotation(additionalParams.asset.type);
    return {
      ...commonPayload,
      customer_name: additionalParams.fullName,
      id_number: additionalParams.idNumber,
      email_address: additionalParams.email,
      lender_name: additionalParams.lenderName,
      comments: additionalParams.comments,
      creation_date:
        additionalParams.createdAt || dateHelper(financialValues.startDate),
      name: additionalParams.name,
      broker_name: additionalParams.brokerName,
      client_type: getClientTypeName(additionalParams.clientType),
      assets: [
        {
          cost: assetCost,
          quantity: 1,
          sub_type: asset?.subType,
          category: asset?.category,
          type: asset?.type,
          rv_balloon_amount: pounds.format(
            Number(financialValues.balloonPayment)
          ),
          total_rv_balloon_amount: pounds.format(
            Number(financialValues.balloonPayment)
          ),
          total_cost: assetCost,
        },
      ],
      reference_number: "",
      customer_reference_id: "",
    };
  }

  if (documentType === DownloadDocument.PROPOSAL) {
    const { proposalValues } = params;

    const { customerDetails, proposalDetails } = proposalValues;
    const clientTypeName = getClientTypeName(customerDetails.clientType);

    const isIndividualCustomer = clientTypeName === ClientTypes.INDIVIDUAL;
    const customerName = isIndividualCustomer
      ? getCustomerFullName(customerDetails.firstName, customerDetails.lastName)
      : customerDetails.companyName;

    const customerEmail = isIndividualCustomer
      ? customerDetails.emailAddress
      : customerDetails.directorDetails?.find(
          (director) => director.role === DirectorRoles.CONTACT_PERSON
        )?.emailAddress;

    const selectedLender = setups.lenders?.find(
      (lender) => lender.name === proposalDetails.lenderName
    );

    const contactPerson: TDirector | undefined =
      customerDetails.directorDetails.find(
        (director) => director.role === DirectorRoles.CONTACT_PERSON
      );
    const contactPersonName = getCustomerFullName(
      contactPerson?.firstName,
      contactPerson?.lastName
    );

    return {
      ...commonPayload,
      name: proposalDetails.name,
      broker_name: proposalDetails.brokerName,
      client_type: clientTypeName,
      customer_name: customerName,
      contact_number: customerDetails.contactNumber,
      lender_name: proposalDetails.lenderName,
      lender_email: selectedLender?.email,
      email_address: customerEmail,
      id_number: isIndividualCustomer
        ? customerDetails.idNumber
        : customerDetails.companyRegNum,
      trading_as: customerDetails.tradingAs,
      years_in_business: customerDetails.yearsInBusiness,
      nature_of_business: customerDetails.natureOfBusiness,
      address: getFormattedAddress(customerDetails.address),
      product_type: setups.productTypes.find(
        (pt) => pt.code === proposalDetails.productType
      )?.name,
      introducer_contact: "", // Need to look into this
      reference_number: proposalValues.identifier,
      customer_reference_id: customerDetails.identifier || "",
      contact_person_name: contactPersonName,
      contact_person_email: contactPerson?.emailAddress || "",
      contact_person_contact: contactPerson?.contactNumber || "",
      contact_person_address: contactPerson?.address || "",
      credit_documents:
        proposalValues.documents?.map((doc) => {
          return {
            name: doc.name,
            type: doc.type,
            state: doc.isMandatory ? "Mandatory" : "Optional",
            status: doc.documents.length > 0 ? "Uploaded" : "Missing",
            attachments:
              doc.documents.length > 0
                ? doc.documents.map((document) => document.key)
                : [],
          };
        }) || [],
      director_details:
        customerDetails.directorDetails?.reduce((directors, director) => {
          if (director.role === DirectorRoles.CONTACT_PERSON) return directors;
          directors.push({
            full_name: `${director.firstName ?? ""} ${director.lastName ?? ""}`,
            email: director.emailAddress,
            contact_number: director.contactNumber,
            address: director.address,
          });
          return directors;
        }, []) || [],
      assets:
        proposalValues.assets?.map((asset) => {
          return {
            category: setups.assetCategories?.find(
              (category) => category.code === asset.category
            )?.description,
            type: setups.assetTypes?.find(
              (category) => category.code === asset.type
            )?.name,
            age: `${asset.age}`,
            rv_balloon_amount: pounds.format(Number(asset.rvBalloonAmount)),
            total_rv_balloon_amount: pounds.format(
              Number(asset.totalRvBalloonAmount)
            ),
            total_cost: pounds.format(Number(asset.totalCost)),
            cost: pounds.format(Number(asset.cost)),
            quantity: asset.quantity,
            sub_type: setups.assetSubTypes?.find(
              (ast) => ast.code === asset.subType
            )?.name,
            supplier_name: asset.supplierName,
            description: asset.description,
            condition: setups.assetConditions?.find(
              (conditions) => conditions.code === asset.condition
            )?.description,
          };
        }) || [],
      underwriter_comments:
        proposalValues.orderComments
          ?.filter(
            (comment) => comment.category === CommentCategory.UNDERWRITING
          )
          .map((comment) => ({
            category: comment.category,
            comment: comment.comment,
            created_by: comment.createdBy,
          })) || [],
    };
  }
};

export const getCustomerFullName = (firstName: string, lastName: string) =>
  [firstName, lastName].filter(Boolean).join(" ");

export const getFormattedDate = (
  date: string,
  format: string
): string | null => {
  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) return null;

  return parsedDate.format(format);
};
