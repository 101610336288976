import { useProposalFieldArrays } from "@contexts/proposal-form";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { AssetDetailsForm } from "@components/proposal/asset-details";
import { TAssetDetails, TProposalDetails, TProposalForm } from "@_types";
import {
  useSumOfAssetCost,
  useAssetSetups,
  useSumOfRvBalloonAmount,
} from "@hooks";
import {
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useSnackbarContext } from "@contexts/snackbar";

const AddAssetForm = () => {
  const { assetCategories } = useAssetSetups();
  const { setValue: setValueQuotationForm } =
    useQuotationFormContext<QuotationForm>();
  const sumOfAssetCost = useSumOfAssetCost();
  const sumOfRvBalloonAmount = useSumOfRvBalloonAmount();
  const { watch: proposalWatch } = useFormContext<TProposalForm>();
  const proposalCurrency = proposalWatch("proposalDetails.currency");
  const formMethods = useForm<TAssetDetails>({
    defaultValues: {
      category: assetCategories[0]?.code,
      type: "",
      subType: "",
      condition: "",
      totalCost: 0,
      quantity: 1,
      totalRvBalloonAmount: 0,
    },
  });

  const {
    assetMethods: { append },
  } = useProposalFieldArrays();

  const { watch } = formMethods;

  const totalCost = watch("totalCost");
  const totalRvBalloonAmount = watch("totalRvBalloonAmount");
  const { setSnackbar } = useSnackbarContext();
  const handleSaveAsset = () => {
    append({
      ...formMethods.getValues(),
      totalCost: totalCost,
      totalRvBalloonAmount: totalRvBalloonAmount,
    });

    setValueQuotationForm("assetCost", sumOfAssetCost + totalCost);
    setValueQuotationForm(
      "balloonPayment",
      sumOfRvBalloonAmount + totalRvBalloonAmount
    );
    formMethods.reset();
    setSnackbar({ open: true, message: "Asset Saved Successfully." }, 2000);
  };

  return (
    <FormProvider {...formMethods}>
      <AssetDetailsForm
        handleSaveAsset={handleSaveAsset}
        proposalCurrency={proposalCurrency}
      />
    </FormProvider>
  );
};

export default AddAssetForm;
