import { useFormContext } from "react-hook-form";
import {
  ClientTypes,
  OrderStatus,
  ProposalStakeholders,
  FinanceType,
  Roles,
} from "@helpers/enum";
import { useSnackbarContext } from "@contexts/snackbar";
import {
  TOrderAssetsReq,
  TCustomerPartnersReq,
  TProposalPayloadReq,
  TFinancialDetailReq,
  TCustomerDocumentsReq,
  TProposalCustomerReq,
  TProposalForm,
  TDirector,
  TAssetDetails,
} from "@_types";
import { useGetMutateQuotationPayload } from "@ntpkunity/controls-common";
import {
  convertMoveInDateStringToDate,
  getCreditTierScore,
  getUserDataFromToken,
  getUserSettings,
} from "@helpers/utils";
import { useWorkflowContext } from "@components/workflows";
import {
  useGetClientTypeName,
  useGetFinanceTypeName,
  useGetDealers,
  useSetupsSelector,
} from "@hooks";

const getAssetsPayload = (
  assets: TAssetDetails[],
  isEditMode: boolean
): TOrderAssetsReq[] => {
  return assets?.map((asset) => {
    const assetPayload = {
      category: asset.category,
      asset_type: asset.type,
      asset_sub_type: asset.subType,
      supplier_name: asset.supplierName,
      description: asset.description,
      unit_price: Number(asset.cost || 0),
      quantity: asset.quantity,
      total: asset.totalCost,
      age: asset.age,
      condition: asset.condition ? [asset.condition] : undefined,
      rv_balloon_amount: asset.rvBalloonAmount,
    };

    if (isEditMode) {
      return {
        ...assetPayload,
        identifier: asset?.identifier,
      };
    }
    return assetPayload;
  });
};

const getPartnersPayload = (
  partners: TDirector[],
  isEditMode: boolean
): TCustomerPartnersReq[] => {
  return partners?.map((partner) => {
    const partnerDetails = {
      first_name: partner.firstName,
      last_name: partner.lastName,
      email: partner.emailAddress,
      contact_number: partner.contactNumber,
      address: {
        address_line_1: partner.address,
        address_type: "Mailing" as const,
      },
      role: partner.role,
    };

    if (isEditMode) {
      return { ...partnerDetails, identifier: partner?.identifier };
    }
    return partnerDetails;
  });
};

const useGetSaveProposalPayload = (identifier?: string) => {
  const isEditMode = !!identifier;
  const { getValues, watch } = useFormContext<TProposalForm>();
  const { setSnackbar } = useSnackbarContext();
  const setups = useSetupsSelector((state) => ({
    creditTiers: state.creditTiers,
  }));
  const creditTiers = setups.creditTiers.data;
  const getQuotationPayload = useGetMutateQuotationPayload({
    forUpdate: isEditMode,
  });
  const workflowTask = useWorkflowContext();
  const financeCode = watch("proposalDetails.financeType");
  const financeType = useGetFinanceTypeName(financeCode);
  const clientCode = watch("customerDetails.clientType");
  const clientType = useGetClientTypeName(clientCode);
  const isAssetsTabDisabled = financeType === FinanceType.LOAN;
  const { data: dealerAssociation } = useGetDealers();
  const userSettings = getUserSettings();
  const brokerId =
    userSettings?.role?.name == Roles.BROKER_USER
      ? getUserDataFromToken()?.user_id
      : null;

  const getPayload = (
    status: OrderStatus,
    lenderData?,
    dealerData?
  ): TProposalPayloadReq => {
    const formValues = getValues();
    let financialDetails = getQuotationPayload();
    if (isEditMode) {
      financialDetails = { ...financialDetails } as TFinancialDetailReq;
    } else {
      financialDetails = {
        customer: financialDetails.quote_identifiers,
        ...financialDetails.quote_details,
      } as TFinancialDetailReq;
    }

    const customerDocuments: TCustomerDocumentsReq[] = [];

    formValues.documents.forEach((docs) => {
      if (docs.documents.length > 0) {
        docs.documents.forEach((doc) => {
          customerDocuments?.push({
            document_type: docs.type,
            document_name: docs.name,
            is_mandatory: docs.isMandatory,
            document_reference_id: doc.key,
            content_type: doc.file ? doc.file.type : "",
            identifier: doc.identifier,
            document_size: doc.file ? doc?.file.size : 0,
          });
        });
      } else {
        customerDocuments.push({
          document_type: docs.type,
          document_name: docs.name,
          is_mandatory: docs.isMandatory,
          document_reference_id: undefined,
          content_type: undefined,
          identifier: docs.identifier,
          document_size: 0,
        });
      }
    });

    let customerDetails: TProposalCustomerReq = {
      identifier: isEditMode ? formValues.customerDetails.identifier : null,
      customer_type: formValues.customerDetails.clientType,
      customer_documents: customerDocuments,
      credit_rating: formValues.customerDetails.creditTier,
      credit_score: getCreditTierScore(
        creditTiers,
        formValues.customerDetails.creditTier
      ),
      address: {
        identifier: isEditMode
          ? formValues.customerDetails.address.identifier
          : null,
        address_line_1: formValues.customerDetails.address.addressLine1,
        address_line_2: formValues.customerDetails.address.addressLine2,
        city: formValues.customerDetails.address.city,
        zip_code: formValues.customerDetails.address.zipCode,
        county: formValues.customerDetails.address.county,
        move_in_date: convertMoveInDateStringToDate(
          formValues.customerDetails.address.moveInDate
        ) as Date,
      },
    };

    if (clientType === ClientTypes.INDIVIDUAL) {
      customerDetails = {
        ...customerDetails,
        first_name: formValues.customerDetails.firstName,
        last_name: formValues.customerDetails.lastName,
        email: formValues.customerDetails.emailAddress,
        mobile_number: formValues.customerDetails.contactNumber,
        id_number: formValues.customerDetails.idNumber,
      };
    } else {
      customerDetails = {
        ...customerDetails,
        company_registration_number: formValues.customerDetails.companyRegNum,
        trading_as: formValues.customerDetails.tradingAs,
        year_business_established: formValues.customerDetails.yearsInBusiness,
        nature_of_business: formValues.customerDetails.natureOfBusiness,
        sic_code: formValues.customerDetails.sicCode,
        company_name: formValues.customerDetails.companyName,
        partners: getPartnersPayload(
          formValues.customerDetails.directorDetails,
          isEditMode
        ),
      };
    }

    const orderComments = formValues.orderComments?.map((comment) => ({
      identifier: comment.identifier,
      order_reference_id: comment.orderReferenceId,
      customer_id: comment.customerId,
      comment: comment.comment,
      category: comment.category,
      created_at: comment.createdAt,
      created_by: comment.identifier
        ? comment.createdByEmail
        : getUserSettings()?.email,
      updated_at: comment.updatedAt,
      updated_by: comment.updatedBy,
    }));

    return {
      ...financialDetails,
      identifier: isEditMode ? identifier : null,
      reference_number: isEditMode ? identifier : null,
      customer: customerDetails,
      status: status,
      order_assets: isAssetsTabDisabled
        ? []
        : getAssetsPayload(formValues.assets, isEditMode),
      name: formValues.proposalDetails.name,
      introducer_name: formValues.proposalDetails.brokerName,
      finance_type: formValues.proposalDetails.financeType,
      product_type: formValues.proposalDetails.productType,
      order_stakeholders: [
        ...(formValues.proposalDetails.dealerName
          ? [
              {
                name: formValues.proposalDetails.dealerName,
                role: ProposalStakeholders.DEALER,
                stakeholder_reference_id: [dealerData?.id],
              },
            ]
          : []),
        ...(formValues.proposalDetails.lenderName
          ? [
              {
                name: formValues.proposalDetails.lenderName,
                role: ProposalStakeholders.LENDER,
                stakeholder_reference_id: [lenderData?.id],
              },
            ]
          : []),
      ],
      order_comments: orderComments,
      created_by: isEditMode
        ? formValues.proposalDetails.createdBy
        : getUserSettings()?.email || "",
      running_id: workflowTask.running_id,
      dealer_id: formValues.proposalDetails.dealerId
        ? formValues.proposalDetails.dealerId
        : dealerAssociation?.id,
      broker_id: formValues.proposalDetails.brokerId
        ? formValues.proposalDetails.brokerId
        : brokerId,
    };
  };

  const validateProposalPayload = (payload: TProposalPayloadReq) => {
    if (!payload.name) {
      setSnackbar({ open: true, message: "Please enter proposal name" });
      return false;
    }
    return true;
  };

  return { getPayload, validateProposalPayload };
};

export default useGetSaveProposalPayload;
