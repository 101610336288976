import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu, Tooltip } from "@ntpkunity/controls";
import { QuotationTableRowProps } from "./quotation-table-row.props";
import dayjs from "dayjs";
import { TableRowOptions } from "@helpers/enum";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { FinanceTypeCell, CustomerTypeCell } from "@components/work-queue";
import { TQuotationWorkQueueEntry } from "@_types";
import { useGetCurrency, useSetupsSelector } from "@hooks";

const QuotationTableRow = ({
  quotation,
  convertToProposal,
  openDownloadDialog,
}: QuotationTableRowProps) => {
  const theme = useTheme();
  const updatedAt = dayjs(quotation.updated_at, "YYYY-MM-DD");
  const navigate = useNavigate();
  const { isLoading: financeTypesLoading, isError: isErrorInFinanceTypes } =
    useSetupsSelector((state) => state.financeTypes);
  const { data: currency } = useGetCurrency(quotation.order_currency);

  const handleOptionClick = (
    _,
    key: TableRowOptions,
    quote: TQuotationWorkQueueEntry
  ) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        convertToProposal(quote.identifier);
        break;
      case TableRowOptions.VIEW_EDIT:
        navigate(`${APP_ROUTES.QUOTATION}/${quote.identifier}`);
        break;
      case TableRowOptions.CLONE_QUOTATION:
        navigate(`${APP_ROUTES.QUOTATION}/${quote.identifier}`);
        navigate(APP_ROUTES.QUOTATION, {
          state: { quotationId: quote.identifier },
        });
        break;
      case TableRowOptions.DOWNLOAD:
        openDownloadDialog({
          clientType: quote.customer_type,
          identifier: quote.identifier,
          rowOptions: key,
        });
        break;
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>
        <Tooltip title={quotation.name} placement="top" theme={theme}>
          <>{quotation.name}</>
        </Tooltip>
      </TableCell>
      <TableCell>
        <FinanceTypeCell financeTypeCode={quotation.finance_type} />
      </TableCell>
      <TableCell className="text-right">
        {currency?.currency?.format(quotation.finance_amount)}
      </TableCell>
      <TableCell>
        <CustomerTypeCell customerTypeCode={quotation.customer_type} />
      </TableCell>
      <TableCell>{updatedAt.format("DD/MM/YYYY")}</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              optionText: "View/Edit",
              optionkey: TableRowOptions.VIEW_EDIT,
              optionValue: quotation,
            },
            {
              optionText: "Convert to proposal",
              optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
              optionValue: quotation,
            },
            {
              optionText: "Download quotation",
              optionkey: TableRowOptions.DOWNLOAD,
              optionValue: quotation,
              disabled: financeTypesLoading || isErrorInFinanceTypes,
            },
            {
              optionText: "Clone quotation",
              optionkey: TableRowOptions.CLONE_QUOTATION,
              optionValue: quotation,
              disabled: financeTypesLoading || isErrorInFinanceTypes,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default QuotationTableRow;
