import {
  FinancialConfigurations,
  LayoutWithSideNavComponent,
  ConfigurationWrapper as ConfigurationContextProvider,
  Snackbar,
} from "@components";
import { useTheme } from "@mui/material";
import { Box } from "@ntpkunity/controls";
import {
  ConfigurationsPageContentWrap,
  PageContent,
} from "./financial-configurations.style";

export const FinancialConfigurationsPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ConfigurationsPageContentWrap
        theme={theme}
        className="configurations-page-content-wrap"
      >
        <ConfigurationContextProvider>
          <FinancialConfigurations />
        </ConfigurationContextProvider>
      </ConfigurationsPageContentWrap>
      <Snackbar />
    </LayoutWithSideNavComponent>
  );
};
