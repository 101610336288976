import { useGetDealers, useGetFinanceTypes } from "@hooks/queries";
import {
  getEntityTypeDealer,
  isDealerRole,
  removeDeletedEntities,
} from "@helpers/utils";
import useGetDealerProfile from "@hooks/queries/useGetDealerProfile";

export const useFilteredFinanceTypes = (_financeType?: string) => {
  const _isDealerRole = isDealerRole();
  const {
    isLoading: financeTypesLoading,
    isError: isErrorInFinanceTypes,
    data: allFinanceTypes,
  } = useGetFinanceTypes();
  let financeTypes = removeDeletedEntities(_financeType, allFinanceTypes);
  const {
    data: dealerAssociation,
    isLoading: dealerAssociationLoading,
    isError: dealerAssociationError,
  } = useGetDealers(getEntityTypeDealer());

  const dealerCode = _isDealerRole && dealerAssociation?.[0]?.dealer_code;

  const {
    isLoading: dealerProfileLoading,
    isError: isDealerProfileError,
    data: dealerProfile,
  } = useGetDealerProfile(dealerCode);

  const availableFinanceTypes = dealerProfile?.available_finance_types || [];
  financeTypes = !_isDealerRole
    ? financeTypes
    : financeTypes?.filter(
        (type) =>
          type.code === _financeType ||
          availableFinanceTypes.includes(type.name.trim())
      );
  const isFinanceTypeError =
    isErrorInFinanceTypes ||
    (_isDealerRole && (isDealerProfileError || dealerAssociationError));

  const isFinanceTypeLoading =
    financeTypesLoading ||
    (_isDealerRole && (dealerProfileLoading || dealerAssociationLoading));

  return {
    financeTypes,
    isFinanceTypeError,
    isFinanceTypeLoading,
  };
};
