import { AuthLayout, SignInForm } from "@components";
import { useTheme } from "@mui/material";
import LoginSideBarImage from "../../../public/assets/images/login-sidebar-anglo.svg";
import AppLogo from "../../../public/assets/images/anglo-scottish-logo.svg";

export const SignInPage = () => {
  const theme = useTheme();

  return (
    <AuthLayout logoImage={AppLogo} sideBarImage={LoginSideBarImage}>
      <SignInForm title={"Anglo Scottish"} />
    </AuthLayout>
  );
};
